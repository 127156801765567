import { getRequest, postRequest, getcontractIdNumber } from './api'
import { API_URL_WORKORDER } from '../constants/index'

export async function checkAssetIsUsing(inventoryID, contractID, workOrderID) {
  return getRequest(
    `workOrder/get-check-asset-is-using/${inventoryID || '%20'}/${contractID ||
      '%20'}/${workOrderID || '%20'}`,
  )
}

export async function listByMainInventoryID(mainInventoryID) {
  return getRequest(`workOrder/getbymaininventory?mainInventoryID=${mainInventoryID}`)
}

export async function getWorkOrders2(
  size,
  index,
  containsValues,
  orderByValues,
  completedDate,
  orderNumber,
  workOrderStatus,
  mainInventoryNumber,
  inventoryNumber,
  workOrderType,
  areaID,
  locationID,
  address,
  callerName,
  trackingNumber,
  partNumbers,
  technicianNames,
) {
  const url = new URL(`${API_URL_WORKORDER}workOrder/get-list2`)

  const queryString = url.search

  const searchParams = new URLSearchParams(queryString)

  const contractID = getcontractIdNumber()

  if (contractID !== 0) {
    searchParams.append('contractID', contractID)
  }

  if (size !== null && size !== undefined) {
    searchParams.append('size', size)
  }

  if (index !== null && index !== undefined) {
    searchParams.append('index', index)
  }

  if (containsValues !== null && containsValues !== undefined) {
    searchParams.append('containsValues', containsValues)
  }

  if (orderByValues !== null && orderByValues !== undefined) {
    searchParams.append('orderByValues', orderByValues)
  }

  if (completedDate !== null && completedDate !== undefined) {
    searchParams.append('completedDate', completedDate)
  }

  if (workOrderStatus !== null && workOrderStatus !== undefined) {
    searchParams.append('workOrderStatus', workOrderStatus)
  }

  if (orderNumber !== null && orderNumber !== undefined) {
    searchParams.append('orderNumber', orderNumber)
  }

  if (address !== null && address !== undefined) {
    searchParams.append('address', address)
  }

  if (areaID !== null && areaID !== undefined) {
    searchParams.append('areaID', areaID)
  }

  if (locationID !== null && locationID !== undefined) {
    searchParams.append('locationID', locationID)
  }

  if (workOrderType !== null && workOrderType !== undefined) {
    searchParams.append('workOrderType', workOrderType)
  }

  if (inventoryNumber !== null && inventoryNumber !== undefined) {
    searchParams.append('inventoryNumber', inventoryNumber)
  }

  if (callerName !== null && callerName !== undefined) {
    searchParams.append('callerName', callerName)
  }

  if (trackingNumber !== null && trackingNumber !== undefined) {
    searchParams.append('trackingNumber', trackingNumber)
  }

  if (partNumbers !== null && partNumbers !== undefined) {
    searchParams.append('partNumbers', partNumbers)
  }

  if (technicianNames !== null && technicianNames !== undefined) {
    searchParams.append('technicianNames', technicianNames)
  }

  if (mainInventoryNumber !== null && mainInventoryNumber !== undefined) {
    searchParams.append('mainInventoryNumber', mainInventoryNumber)
  }

  url.search = searchParams.toString()

  const newUrl = url.toString()

  return getRequest(newUrl)
}

export async function find(id) {
  return getRequest(`workOrder/edit/${id}`)
}
export async function nextControl(contractId, startDate, endDate) {
  return getRequest(`workOrder/get-next-control-date/${contractId}/${startDate}/${endDate}`)
}
export async function get(id) {
  return getRequest(`workOrder/get-workorder/${id}`)
}
export async function getAppointmenDate(id) {
  return getRequest(`workOrder/get-appointment-date/${id}`)
}
export async function defaults(id) {
  return getRequest(`workOrder/defaults/${id}`)
}

export async function save(values) {
  return postRequest(`workOrder/save`, values)
}

export async function createWorkOrderByProposal(proposalID) {
  console.log('createWorkOrderByProposal proposalID', proposalID)
  return postRequest(`workOrder/create-from-proposal/${proposalID}`, { values: {} })
}

export async function logAssetChange(values) {
  return postRequest(`workOrder/log-asset-from-web`, values)
}

export async function saveAsset(values) {
  return postRequest(`workOrder/save-asset`, values)
}

export async function saveService(values) {
  return postRequest(`workOrder/save-service`, values)
}

export function remove(values) {
  return postRequest(`workOrder/delete`, values)
}

export function createWorkorders(values, startDate, endDate) {
  const params = {
    values: {
      contractID: getcontractIdNumber(),
      orderNumbers: values,
      startDate,
      endDate,
    },
  }
  return postRequest(`workOrder/create-workorders`, params)
}

export function savePdf(workOrderID, file) {
  const params = {
    values: {
      workOrderID,
      file,
    },
  }
  return postRequest(`workOrder/save-pdf`, params)
}

export async function create() {
  const ContractID = getcontractIdNumber()
  return getRequest(`workOrder/create/${ContractID}`)
}

export async function recall(workOrderID, orderNumber) {
  return getRequest(`workOrder/recall/${workOrderID}/${orderNumber || '%20'}`)
}
export async function generalCostView(workOrderID) {
  return getRequest(`workOrder/get-general-contract-cost-view2/${workOrderID}`)
}
export async function costView(id) {
  return getRequest(`workOrder/get-costview/${id}`)
}
