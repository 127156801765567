import { API_URL } from '../constants/index'
import { getRequest, postRequest, getcontractIdNumber, deleteRequest } from './api'

export async function list(size, index, containsValues, orderByValues) {
  if (!containsValues.includes('ContractID')) {
    containsValues = `${containsValues}, ContractID:${getcontractIdNumber()},`
  }

  const url = new URL(`${API_URL}invoice2`)

  const queryString = url.search

  const searchParams = new URLSearchParams(queryString)

  if (size !== null && size !== undefined) {
    searchParams.append('size', size)
  }

  if (index !== null && index !== undefined) {
    searchParams.append('index', index)
  }

  if (containsValues !== null && containsValues !== undefined) {
    searchParams.append('containsValues', containsValues)
  }

  if (orderByValues !== null && orderByValues !== undefined) {
    searchParams.append('orderByValues', orderByValues)
  }

  url.search = searchParams.toString()

  const newUrl = url.toString()

  return getRequest(newUrl)
}

export async function find(id) {
  return getRequest(`${API_URL}invoice2/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL}invoice2`, values)
}

export function remove(id) {
  return deleteRequest(`${API_URL}invoice2/${id}`)
}

export async function createInvoice() {
  const id = getcontractIdNumber()
  const values = {
    values: {
      contractID: id,
    },
  }
  return postRequest(`${API_URL}invoice2/byContractID`, values)
}

export async function createInvoiceByWorkOrderID(id) {
  const values = {
    values: {
      workOrderID: id,
    },
  }
  return postRequest(`${API_URL}invoice2/byWorkOrderID`, values)
}
