import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getWorkOrders, getInventories, sendEmail } from '../../services/report'
import { SAVE_200_MSG, SAVE_200_DESC } from '../../constants'
import actions from './actions'

function* fetchSendEmail() {
  yield put({
    type: actions.REQUESTING,
  })
  yield call(sendEmail)
  notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
}

function* fetchInvetories({ intervalId, areaId, locationId, addressCode, begin, end, excel }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getInventories,
    intervalId,
    areaId,
    locationId,
    addressCode,
    begin,
    end,
    excel,
  )
  yield put({
    type: actions.FETCH_INVENTORY_REPORTS_RECIEVED,
    result,
  })
}

function* fetchWorkOrders({
  workOrderStatus,
  areaId,
  locationId,
  addressCode,
  begin,
  end,
  workOrderType,
  inventoryNumber,
  excel,
}) {
  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(
    getWorkOrders,
    workOrderStatus,
    areaId,
    locationId,
    addressCode,
    begin,
    end,
    workOrderType,
    inventoryNumber,
    excel,
  )
  yield put({
    type: actions.FETCH_WORKORDER_REPORTS_RECIEVED,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKORDER_REPORTS, fetchWorkOrders),
    takeEvery(actions.FETCH_INVENTORY_REPORTS, fetchInvetories),
    takeEvery(actions.FETCH_WORKORDER_SENDEMAIL, fetchSendEmail),
  ])
}
