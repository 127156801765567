const actions = {
  REQUESTING: 'WorkOrders/REQUESTING',
  RESPONSE_ERROR: 'WorkOrders/RESPONSE_ERROR',

  FETCH_WORKORDERS_FILTER: 'WorkOrders/FETCH_FILTER',
  LIST_WORKORDER: 'WorkOrder/LIST',

  FETCH_WORKORDER_COSTVIEW: 'WorkOrders/FETCH_WORKORDER_COSTVIEW',
  FETCH_WORKORDER_COSTVIEW_RECIEVED: 'WorkOrders/FETCH_WORKORDER_COSTVIEW_RECIEVED',
  FETCH_WORKORDER_COSTVIEW_RESET: 'WorkOrders/FETCH_WORKORDER_COSTVIEW_RESET',
  FETCH_WORKORDER_COSTVIEW_RESET_RECIEVED: 'WorkOrders/FETCH_WORKORDER_COSTVIEW_RESET_RECIEVED',

  FETCH_WORKORDER: 'WorkOrder/FETCH',
  NEW_WORKORDER: 'WorkOrder/NEW',
  EDIT_WORKORDER: 'WorkOrder/EDIT',
  RESET_WORKORDER: 'WorkOrder/RESET',

  SAVE_WORKORDER: 'WorkOrder/SAVE',
  SAVE_WORKORDER_RECIEVED: 'WorkOrder/SAVE_WORKORDER_RECIEVED',

  DELETE_WORKORDER: 'WorkOrder/DELETE',
  DELETE_WORKORDER_RECIEVED: 'WorkOrder/DELETE_RECIEVED',

  FETCH_NEXTCONTROLS: 'WorkOrder/FETCH_NEXTCONTROLS',
  FETCH_NEXTCONTROLS_RECIEVED: 'WorkOrder/FETCH_NEXTCONTROLS_RECIEVED',

  CREATE_AUTO_WORKORDERS: 'WorkOrders/CREATE_AUTO',
  CREATE_AUTO_WORKORDERS_CATCH: 'WorkOrders/CREATE_AUTO_CATCH',

  SKIP_AUTO_WORKORDERS: 'WorkOrders/SKIP_AUTO',

  CREATE_NEW_WORKORDER: 'WorkOrder/CREATE',
  CREATE_NEW_WORKORDER_RECIEVED: 'WorkOrder/CREATE_RECIEVED',

  CREATE_NEW_WORKORDER_RESET: 'WorkOrder/CREATE_RESET',
  CREATE_NEW_WORKORDER_RESET_REQ: 'WorkOrder/CREATE_RESET_REQ',
  CREATE_NEW_WORKORDER_RESET_RECIEVED: 'WorkOrder/CREATE_RESET_RECIEVED',

  FETCH_WORKORDER_ASSET_REPORT: 'WorkOrders/FETCH_WORKORDER_ASSET_REPORT',
  FETCH_WORKORDER_ASSET_REPORT_RESET: 'WorkOrders/FETCH_WORKORDER_ASSET_REPORT_RESET',
  FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED:
    'WorkOrders/FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED',
  FETCH_WORKORDER_ASSET_REPORT_RECIEVED: 'WorkOrder/FETCH_WORKORDER_ASSET_REPORT_RECIEVED',
  FETCH_WORKORDER_ASSET_COUNT: 'WorkOrders/FETCH_WORKORDER_ASSET_COUNT',
  FETCH_WORKORDER_ASSET_COUNT_RECIEVED: 'WorkOrders/FETCH_WORKORDER_ASSET_COUNT_RECIEVED',

  RECALL_WORKORDER: 'WorkOrder/RECALL',
  RECALL_WORKORDER_RECIEVED: 'WorkOrder/RECALL_RECIEVED',

  SAVE_ASSET: 'WorkOrder/SAVE_ASSET',
  SAVE_SERVICE: 'WorkOrder/SAVE_SERBICE',

  SAVEPDF: 'WorkOrder/SAVEPDF',
  SAVEPDF_RECIEVED: 'WorkOrder/SAVEPDF_RECIEVED',

  PRINT_POPUP: 'WorkOrder/PRINT_POPUP',
  PRINT_POPUP_RECIEVED: 'WorkOrder/PRINT_POPUP_RECIEVED',
  PRINT_POPUP_RESET: 'WorkOrder/PRINT_POPUP_RESET',
  PRINT_POPUP_RESET_RECIEVED: 'WorkOrder/PRINT_POPUP_RESET_RECIEVED',

  GET_APPOINTMENT_DATE: 'WorkOrder/GET_APPOINTMENT_DATE',
  GET_APPOINTMENT_DATE_RECIEVED: 'WorkOrder/GET_APPOINTMENT_DATE_RECIEVED',

  GET_APPOINTMENT_DATE_RESET: 'WorkOrder/GET_APPOINTMENT_DATE_RESET',
  GET_APPOINTMENT_DATE_RESET_RECIEVED: 'WorkOrder/GET_APPOINTMENT_DATE_RESET_RECIEVED',

  GET_COSTVIEW2: 'WorkOrder/GET_COSTVIEW2',
  GET_COSTVIEW_RECIVEVED2: 'WorkOrder/GET_COSTVIEW_RECIVEVED2',

  ADD_INVENTORY: 'WorkOrder/ADD_INVENTORY',
  ADD_INVENTORY_RECIEVED: 'WorkOrder/ADD_INVENTORY_RECIEVED',
  ADD_INVENTORY_RESET: 'WorkOrder/ADD_INVENTORY_RESET',
  ADD_INVENTORY_RESET_RECIEVED: 'WorkOrder/ADD_INVENTORY_RESET_RECIEVED',

  GET_WORKORDERSTATUS: 'GET_WORKORDERSTATUS',
  GET_WORKORDERSTATUS_RECIEVED: 'GET_WORKORDERSTATUS_RECIEVED',

  POPUP_BUDGET: 'WorkOrder/POPUP_BUDGET',
  POPUP_BUDGET_RECIEVED: 'WorkOrder/POPUP_BUDGET_RECIEVED',
  POPUP_BUDGET_RESET: 'WorkOrder/POPUP_BUDGET_RESET',
  POPUP_BUDGET_RESET_RECIEVED: 'WorkOrder/POPUP_BUDGET_RESET_RECIEVED',

  OPEN_POPUP_CUSTOMER_SIGNATURE: 'WorkOrder/OPEN_POPUP_CUSTOMER_SIGNATURE',
  OPEN_POPUP_CUSTOMER_SIGNATURE_RECIEVED: 'WorkOrder/OPEN_POPUP_CUSTOMER_SIGNATURE_RECIEVED',
  CLOSE_POPUP_CUSTOMER_SIGNATURE: 'WorkOrder/CLOSE_POPUP_CUSTOMER_SIGNATURE',
  CLOSE_POPUP_CUSTOMER_SIGNATURE_RECIEVED: 'WorkOrder/CLOSE_POPUP_CUSTOMER_SIGNATURE_RECIEVED',

  OPEN_POPUP_TECHNICIAN_WORK: 'WorkOrder/OPEN_POPUP_TECHNICIAN_WORK',
  OPEN_POPUP_TECHNICIAN_WORK_RECIEVED: 'WorkOrder/OPEN_POPUP_TECHNICIAN_WORK_RECIEVED',
  CLOSE_POPUP_TECHNICIAN_WORK: 'WorkOrder/CLOSE_POPUP_TECHNICIAN_WORK',
  CLOSE_POPUP_TECHNICIAN_WORK_RECIEVED: 'WorkOrder/CLOSE_POPUP_TECHNICIAN_WORK_RECIEVED',

  OPEN_POPUP_SERVICE_ORDER_FILE: 'WorkOrder/OPEN_POPUP_SERVICE_ORDER_FILE',
  OPEN_POPUP_SERVICE_ORDER_FILE_RECIEVED: 'WorkOrder/OPEN_POPUP_SERVICE_ORDER_FILE_RECIEVED',
  CLOSE_POPUP_SERVICE_ORDER_FILE: 'WorkOrder/CLOSE_POPUP_SERVICE_ORDER_FILE',
  CLOSE_POPUP_SERVICE_ORDER_FILE_RECIEVED: 'WorkOrder/CLOSE_POPUP_SERVICE_ORDER_FILE_RECIEVED',

  OPEN_POPUP_WORKORDER_COST_SUMMARY: 'WorkOrder/OPEN_POPUP_WORKORDER_COST_SUMMARY',
  OPEN_POPUP_WORKORDER_COST_SUMMARY_RECIEVED:
    'WorkOrder/OPEN_POPUP_WORKORDER_COST_SUMMARY_RECIEVED',
  CLOSE_POPUP_WORKORDER_COST_SUMMARY: 'WorkOrder/CLOSE_POPUP_WORKORDER_COST_SUMMARY',
  CLOSE_POPUP_WORKORDER_COST_SUMMARY_RECIEVED:
    'WorkOrder/CLOSE_POPUP_WORKORDER_COST_SUMMARY_RECIEVED',

  OPEN_POPUP_WORKORDER_PROPOSAL_INFO: 'WorkOrder/OPEN_POPUP_WORKORDER_PROPOSAL_INFO',
  OPEN_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED:
    'WorkOrder/OPEN_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED',
  CLOSE_POPUP_WORKORDER_PROPOSAL_INFO: 'WorkOrder/CLOSE_POPUP_WORKORDER_PROPOSAL_INFO',
  CLOSE_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED:
    'WorkOrder/CLOSE_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED',

  OPEN_POPUP_WORKORDER_EMAIL_SEND: 'WorkOrder/OPEN_POPUP_WORKORDER_EMAIL_SEND',
  OPEN_POPUP_WORKORDER_EMAIL_SEND_RECIEVED: 'WorkOrder/OPEN_POPUP_WORKORDER_EMAIL_SEND_RECIEVED',
  CLOSE_POPUP_WORKORDER_EMAIL_SEND: 'WorkOrder/CLOSE_POPUP_WORKORDER_EMAIL_SEND',
  CLOSE_POPUP_WORKORDER_EMAIL_SEND_RECIEVED: 'WorkOrder/CLOSE_POPUP_WORKORDER_EMAIL_SEND_RECIEVED',

  OPEN_POPUP_WORKORDER_ATTACHMENT: 'WorkOrder/OPEN_POPUP_WORKORDER_ATTACHMENT',
  OPEN_POPUP_WORKORDER_ATTACHMENT_RECIEVED: 'WorkOrder/OPEN_POPUP_WORKORDER_ATTACHMENT_RECIEVED',
  CLOSE_POPUP_WORKORDER_ATTACHMENT: 'WorkOrder/CLOSE_POPUP_WORKORDER_ATTACHMENT',
  CLOSE_POPUP_WORKORDER_ATTACHMENT_RECIEVED: 'WorkOrder/CLOSE_POPUP_WORKORDER_ATTACHMENT_RECIEVED',

  OPEN_POPUP_WORKORDERLISTBYMAININVENTORY: 'WorkOrder/OPEN_POPUP_WORKORDERLISTBYMAININVENTORY',
  OPEN_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED:
    'WorkOrder/OPEN_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED',
  CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY: 'WorkOrder/CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY',
  CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED:
    'WorkOrder/CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED',

  GET_WORKORDERLISTBYMAININVENTORY: 'WorkOrder/GET_WORKORDERLISTBYMAININVENTORY',
  GET_WORKORDERLISTBYMAININVENTORY_RECIEVED: 'WorkOrder/GET_WORKORDERLISTBYMAININVENTORY_RECIEVED',

  CREATE_WORKORDER_BY_PROPOSAL: 'WorkOrder/CREATE_WORKORDER_BY_PROPOSAL',
  CREATE_WORKORDER_BY_PROPOSAL_RECIEVED: 'WorkOrder/CREATE_WORKORDER_BY_PROPOSAL_RECIEVED',

  FETCH_PREVENTIVE_MAINTENANCE_REPORT: 'WorkOrders/FETCH_PREVENTIVE_MAINTENANCE_REPORT',
  FETCH_PREVENTIVE_MAINTENANCE_REPORT_RECIEVED:
    'WorkOrders/FETCH_PREVENTIVE_MAINTENANCE_REPORT_RECIEVED',

  FETCH_BILLING_TABULATION: 'WorkOrders/FETCH_BILLING_TABULATION',
  FETCH_BILLING_TABULATION_RECIEVED: 'WorkOrders/FETCH_BILLING_TABULATION_RECIEVED',

  OPEN_POPUP_WORKORDER_PROTOCOL_HISTORY: 'WorkOrder/OPEN_POPUP_WORKORDER_PROTOCOL_HISTORY',
  OPEN_POPUP_WORKORDER_PROTOCOL_HISTORY_RECEIVED:
    'WorkOrder/OPEN_POPUP_WORKORDER_PROTOCOL_HISTORY_RECEIVED',
  CLOSE_POPUP_WORKORDER_PROTOCOL_HISTORY: 'WorkOrder/CLOSE_POPUP_WORKORDER_PROTOCOL_HISTORY',
  CLOSE_POPUP_WORKORDER_PROTOCOL_HISTORY_RECEIVED:
    'WorkOrder/CLOSE_POPUP_WORKORDER_PROTOCOL_HISTORY_RECEIVED',
}

export default actions
