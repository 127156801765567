import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import { list } from '../../services/protocol'

function* fetchProtocol({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchProtocol'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchProtocol', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_PROTOCOL,
    result,
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_PROTOCOL_LIST, fetchProtocol)])
}
