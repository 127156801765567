export default {
  REQUESTING: 'dropdown/REQUESTING',
  RESPONSE_ERROR: 'dropdown/RESPONSE_ERROR',
  FETCH_DROPDOWN: 'dropdown/FETCH',

  NOTIFICATION_USERS: 'dropdown/NOTIFICATION_USERS',
  DROPDOWN_NOTIFICATION_USERS: 'dropdown/NOTIFICATION_USERS/RECIEVED',

  CONTRACTS: 'dropdown/CONTRACT',
  DROPDOWN_CONTRACT: 'dropdown/CONTRACT/RECIEVED',

  CONTACT_PERSON: 'dropdown/CONTACT_PERSON',
  DROPDOWN_CONTACT_PERSON: 'dropdown/CONTACT_PERSON/RECIEVED',
  DROPDOWN_CONTACT_PERSON_RESET: 'dropdown/CONTACT_PERSON/RESET',
  DROPDOWN_CONTACT_PERSON_RESET_RECIEVED: 'dropdown/CONTACT_PERSON/RESET_RECIEVED',

  DOC_PARENT: 'dropdown/DOC_PARENT',
  DROPDOWN_DOC_PARENT: 'dropdown/DROPDOWN_DOC_PARENT/RECIEVED',

  INVENTORY_CLASS: 'dropdown/INVENTORY_CLASS',
  DROPDOWN_INVENTORY_CLASS: 'dropdown/INVENTORY_CLASS/RECIEVED',

  INVENTORY_CLASS_COST_TYPE: 'dropdown/INVENTORY_CLASS_COST_TYPE',
  DROPDOWN_INVENTORY_CLASS_COST_TYPE: 'dropdown/INVENTORY_CLASS_COST_TYPE/RECIEVED',

  COST_TYPE_DEFINITIONS: 'dropdown/COST_TYPE_DEFINITION',
  DROPDOWN_COST_TYPE_DEFINITIONS: 'dropdown/COST_TYPE_DEFINITIONS/RECIEVED',

  LOCATIONS: 'dropdown/LOCATION',
  DROPDOWN_LOCATION: 'dropdown/LOCATION/RECIEVED',

  AREAS: 'dropdown/AREA',
  DROPDOWN_AREA: 'dropdown/AREA/RECIEVED',

  USERS: 'dropdown/USER',
  DROPDOWN_USERS: 'dropdown/USERS/RECIEVED',

  PARTS: 'dropdown/PARTS',
  DROPDOWN_PARTS: 'dropdown/PARTS/RECIEVED',

  TECHNICIAN_USERS: 'dropdown/TECHNICIAN_USERS',
  DROPDOWN_TECHNICIAN_USERS: 'dropdown/TECHNICIAN_USERS/RECIEVED',

  COST_TYPE: 'dropdown/COST_TYPE',
  DROPDOWN_COST_TYPE: 'dropdown/COST_TYPES/RECIEVED',

  COST_CONCEPT: 'dropdown/COST_CONCEPT',
  DROPDOWN_COST_CONCEPT: 'dropdown/COST_CONCEPTS/RECIEVED',

  ADDRESS: 'dropdown/ADDRESS',
  DROPDOWN_ADDRESS: 'dropdown/ADDRESS/RECIEVED',

  DEVICE: 'dropdown/DEVICE',
  DROPDOWN_DEVICE: 'dropdown/DEVICE/RECIEVED',

  INTERVAL: 'dropdown/INTERVAL',
  DROPDOWN_INTERVAL: 'dropdown/INTERVAL/RECIEVED',

  WORKORDER_TYPE: 'dropdown/WORKORDER_TYPE',
  DROPDOWN_WORKORDER_TYPE: 'dropdown/WORKORDER_TYPE/RECIEVED',

  INVENTORY: 'dropdown/INVENTORY',
  DROPDOWN_INVENTORY: 'dropdown/INVENTORY/RECIEVED',
  DROPDOWN_INVENTORY_RESET: 'dropdown/INVENTORY/RESET',
  DROPDOWN_INVENTORY_RESET_RECIEVED: 'dropdown/INVENTORY/RESET_RECIEVED',

  TECHNICIAN: 'dropdown/TECHNICIAN',
  DROPDOWN_TECHNICIAN: 'dropdown/TECHNICIAN/RECIEVED',

  WORKORDER: 'dropdown/WORKORDER',
  DROPDOWN_WORKORDER: 'dropdown/WORKORDER/RECIEVED',

  SUPPLIER: 'dropdown/SUPPLIER',
  DROPDOWN_SUPPLIER: 'dropdown/SUPPLIER/RECIEVED',

  INVENTORY_SERVICES: 'dropdown/INVENTORY_SERVICES',
  DROPDOWN_INVENTORY_SERVICES: 'dropdown/INVENTORY_SERVICES/RECIEVED',

  MLFB: 'dropdown/MLFB',
  DROPDOWN_MLFB: 'dropdown/MLFB/RECIEVED',

  ADMIN_USERS: 'dropdown/ADMINUSERS',
  DROPDOWN_ADMIN_USERS: 'dropdown/DROPDOWN_ADMIN_USERS/RECIEVED',

  CLIN: 'dropdown/CLIN',
  DROPDOWN_CLIN: 'dropdown/CLIN/CLIN',

  BANK_INFO: 'dropdown/BANK_INFO',
  DROPDOWN_BANK_INFO: 'dropdown/BANK_INFO/RECIEVED',

  CALLER_NAME: 'dropdown/CALLER_NAME',
  DROPDOWN_CALLER_NAME: 'dropdown/CALLER_NAME/RECIEVED',

  COMPANY_ADDRESS: 'dropdown/COMPANY_ADDRESS',
  DROPDOWN_COMPANY_ADDRESS: 'dropdown/COMPANY_ADDRESS/RECIEVED',

  COMPANY: 'dropdown/COMPANY',
  DROPDOWN_COMPANY: 'dropdown/COMPANY/RECIEVED',

  CONTRACTYEAR: 'dropdown/CONTRACTYEAR',
  DROPDOWN_CONTRACTYEAR: 'dropdown/CONTRACTYEAR/RECIEVED',

  MAININVENTORY: 'dropdown/MAININVENTORY',
  DROPDOWN_MAININVENTORY: 'dropdown/MAININVENTORY/RECIEVED',

  PROTOCOL: 'dropdown/PROTOCOL',
  DROPDOWN_PROTOCOL: 'dropdown/PROTOCOL/RECIEVED',
}
