const actions = {
  REQUESTING: 'Reports/REQUESTING',
  RESPONSE_ERROR: 'Reports/RESPONSE_ERROR',

  FETCH_WORKORDER_REPORTS: 'Reports/FETCH_WORKORDER_REPORTS',
  FETCH_WORKORDER_REPORTS_RECIEVED: 'Reports/FETCH_WORKORDER_REPORTS_RECIEVED',
  FETCH_WORKORDER_REPORTS_RESET: 'Reports/FETCH_WORKORDER_REPORTS_RESET',

  FETCH_INVENTORY_REPORTS: 'Reports/FETCH_INVENTORY_REPORTS',
  FETCH_INVENTORY_REPORTS_RECIEVED: 'Reports/FETCH_INVENTORY_REPORTS_RECIEVED',
  FETCH_INVENTORY_REPORTS_RESET: 'Reports/FETCH_INVENTORY_REPORTS_RESET',

  FETCH_WORKORDERCOST_REPORTS_RECIEVED: 'Reports/FETCH_WORKORDERCOST_REPORTS_RECIEVED',
  FETCH_WORKORDERCOST_REPORTS_RESET: 'Reports/FETCH_WORKORDERCOST_REPORTS_RESET',

  FETCH_WONUMBER_WORKORDERCOST_REPORTS: 'Reports/FETCH_WONUMBER_WORKORDERCOST_REPORTS',
  FETCH_WONUMBER_WORKORDERCOST_REPORTS_RECIEVED:
    'Reports/FETCH_WONUMBER_WORKORDERCOST_REPORTS_RECIEVED',
  FETCH_WONUMBER_WORKORDERCOST_REPORTS_RESET: 'Reports/FETCH_WONUMBER_WORKORDERCOST_REPORTS_RESET',

  FETCH_WORKORDER_SENDEMAIL: 'Reports/FETCH_WORKORDER_SENDEMAIL',
  FETCH_WORKORDER_SENDEMAIL_OK: 'Reports/FETCH_WORKORDER_SENDEMAIL_OK',

  FETCH_PART_REPORT: 'Reports/FETCH_PART_REPORT',
  FETCH_PART_REPORT_RECIEVED: 'Reports/FETCH_PART_REPORT_RECIEVED',
  FETCH_PART_REPORT_RESET: 'Reports/FETCH_PART_REPORT_RESET',

  FETCH_MAINTENANCE_REPORT: 'Reports/FETCH_MAINTENANCE_REPORT',
  FETCH_MAINTENANCE_REPORT_RECIEVED: 'Reports/FETCH_MAINTENANCE_REPORT_RECIEVED',
  FETCH_MAINTENANCE_REPORT_RESET: 'Reports/FETCH_MAINTENANCE_REPORT_RESET',
}

export default actions
