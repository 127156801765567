import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/workOrderTechnicianMap'
import actions2 from '../workOrderOperationCosts/actions'

function* fetchWorkOrderTechnicianMapsRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchWorkOrderTechnicianMaps'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_WORKORDERTECHNICIANMAP,
    result,
  })
}

function* fetchWorkOrderTechnicianMaps({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchWorkOrderTechnicianMaps'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem(
    'fetchWorkOrderTechnicianMaps',
    JSON.stringify({ size, index, searchTerm, orderBy }),
  )
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  console.log('fetchWorkOrderTechnicianMaps', result)
  yield put({
    type: actions.LIST_WORKORDERTECHNICIANMAP,
    result,
  })
}

function* fetchWorkOrderTechnicianMapEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_WORKORDERTECHNICIANMAP,
    result,
  })
}

function* fetchWorkOrderTechnicianMapSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({
      message: SAVE_200_MSG,
      description: result.errorMessage || SAVE_200_DESC,
    })
  }
  yield put({
    type: actions.SAVE_WORKORDERTECHNICIANMAP_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERTECHNICIANMAPS,
  })
  yield put({
    type: actions2.AMOUNT_UPDATED,
    result,
  })

  yield put({ type: actions2.RE_FETCH_WORKORDEROPERATIONCOSTS })
  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID })
  yield put({ type: 'WorkOrder/GET_APPOINTMENT_DATE', id: values.values.workOrderID })
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID })
  yield put({ type: 'WorkOrderOperationCosts/FETCH' })
}

function* fetchWorkOrderTechnicianMapDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_WORKORDERTECHNICIANMAP_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERTECHNICIANMAPS,
  })
  yield put({
    type: actions2.AMOUNT_UPDATED,
    result,
  })

  yield put({ type: actions2.RE_FETCH_WORKORDEROPERATIONCOSTS })
  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID })
  yield put({ type: 'WorkOrder/GET_APPOINTMENT_DATE', id: values.values.workOrderID })
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID })
  yield put({ type: 'WorkOrderOperationCosts/FETCH' })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKORDERTECHNICIANMAPS, fetchWorkOrderTechnicianMaps),
    takeEvery(actions.FETCH_WORKORDERTECHNICIANMAP, fetchWorkOrderTechnicianMapEdit),
    takeEvery(actions.SAVE_WORKORDERTECHNICIANMAP, fetchWorkOrderTechnicianMapSave),
    takeEvery(actions.DELETE_WORKORDERTECHNICIANMAP, fetchWorkOrderTechnicianMapDelete),
    takeEvery(actions.RE_FETCH_WORKORDERTECHNICIANMAPS, fetchWorkOrderTechnicianMapsRefresh),
  ])
}
