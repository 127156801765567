import { API_URL } from '../constants/index'
import { getRequest, postRequest, deleteRequest, getInvoice2ID } from './api'

export async function list(size, index, containsValues, orderByValues) {
  const url = new URL(`${API_URL}invoice2Line`)

  const queryString = url.search

  const searchParams = new URLSearchParams(queryString)

  if (size !== null && size !== undefined) {
    searchParams.append('size', size)
  }

  if (index !== null && index !== undefined) {
    searchParams.append('index', index)
  }

  if (containsValues !== null && containsValues !== undefined) {
    searchParams.append('containsValues', containsValues)
  }

  if (orderByValues !== null && orderByValues !== undefined) {
    searchParams.append('orderByValues', orderByValues)
  }

  if (!containsValues.includes('InvoiceID')) {
    searchParams.append('InvoiceID', getInvoice2ID())
  }
  url.search = searchParams.toString()

  const newUrl = url.toString()

  return getRequest(newUrl)
}

export async function find(id) {
  return getRequest(`${API_URL}invoice2Line/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL}invoice2Line`, values)
}

export function remove(id) {
  return deleteRequest(`${API_URL}invoice2Line/${id}`)
}
