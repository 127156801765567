import { getRequest, postRequest, deleteRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  const urlParameters = new URLSearchParams({
    size: size || 15,
    index: index || 0,
    query: searchTerm || null,
    order: orderBy || null,
  })
  return getRequest(`protocol?${urlParameters}`)
}

export async function find(id) {
  return getRequest(`protocol/${id}`)
}

export async function save(values) {
  return postRequest(`protocol`, values)
}

export function remove(id) {
  return deleteRequest(`protocol/${id}`)
}
