import { notification } from 'antd'
import { API_URL_REPORT } from '../constants/index'
import {
  getRequest,
  getcontractIdNumber,
  getRequestReportViewer,
  downloadRequest,
  getWorkorderID,
} from './api'

const token = localStorage.getItem('token')

const returnExcelOrGET = (url, excel) => {
  if (excel) {
    downloadRequest(url)
  } else {
    return getRequest(url)
  }
  return null
}

export async function getClassCodeCosts(
  workOrderStatus,
  location,
  area,
  address,
  begin,
  end,
  excel,
) {
  const contractID = getcontractIdNumber()
  const url = `report/classcode-report/${contractID}/${workOrderStatus || '%20'}/${location ||
    '%20'}/${area || '%20'}/${address || '%20'}
    /${begin || '%20'}/${end || '%20'}/${excel || 'false'}/${token}`
  return returnExcelOrGET(url, excel)
}

export async function getAssetTecReport(inventoryid) {
  const contractid = getcontractIdNumber()
  return getRequest(`report/get-asset-tec-report/${contractid}/${inventoryid}/${token}`)
}

export async function getAssetTecCount(inventoryid, workOrderID) {
  const contractid = getcontractIdNumber()
  return getRequest(
    `report/get-asset-tec-report-count/${contractid}/${inventoryid}/${workOrderID}/${token}`,
  )
}

export async function getPreventiveMaintenanceReport(startDate, endDate) {
  const contractid = getcontractIdNumber()
  return getRequest(
    `report/preventiveMaintenanceReport?contractID=${contractid}&startDate=${startDate}&endDate=${endDate}`,
  )
}

export async function getBillingTabulation(startDate, endDate) {
  const contractid = getcontractIdNumber()
  return getRequest(
    `report/billingTabulation?contractID=${contractid}&startDate=${startDate}&endDate=${endDate}`,
  )
}

export async function getInventories(
  intervalId,
  areaId,
  locationId,
  addressCode,
  begin,
  end,
  excel,
) {
  const contractId = getcontractIdNumber()

  const url = new URL(`${API_URL_REPORT}report/inventoryReport`)

  const queryString = url.search

  const searchParams = new URLSearchParams(queryString)

  if (contractId !== null && contractId !== undefined) {
    searchParams.append('contractId', contractId)
  }

  if (intervalId !== null && intervalId !== undefined) {
    searchParams.append('intervalId', intervalId)
  }

  if (areaId !== null && areaId !== undefined) {
    searchParams.append('areaId', areaId)
  }

  if (locationId !== null && locationId !== undefined) {
    searchParams.append('locationId', locationId)
  }

  if (addressCode !== null && addressCode !== undefined) {
    searchParams.append('addressCode', addressCode)
  }

  if (begin !== null && begin !== undefined) {
    searchParams.append('begin', begin)
  }

  if (end !== null && end !== undefined) {
    searchParams.append('end', end)
  }

  if (excel !== null && excel !== undefined) {
    searchParams.append('excel', excel)
  }

  url.search = searchParams.toString()

  const newUrl = url.toString()

  if (excel) {
    return downloadRequest(newUrl)
  }

  return getRequest(newUrl)
}

export async function getWorkOrders(
  workOrderStatus,
  areaId,
  locationId,
  addressCode,
  begin,
  end,
  workOrderType,
  inventoryNumber,
  excel,
) {
  const contractId = getcontractIdNumber()

  const url = new URL(`${API_URL_REPORT}report/workorderReport`)

  const queryString = url.search

  const searchParams = new URLSearchParams(queryString)

  if (contractId !== null && contractId !== undefined) {
    searchParams.append('contractId', contractId)
  }

  if (workOrderStatus !== null && workOrderStatus !== undefined) {
    searchParams.append('workOrderStatus', workOrderStatus)
  }

  if (areaId !== null && areaId !== undefined && areaId !== '') {
    searchParams.append('areaId', areaId)
  }

  if (locationId !== null && locationId !== undefined && locationId !== '') {
    searchParams.append('locationId', locationId)
  }

  if (addressCode !== null && addressCode !== undefined && addressCode !== '') {
    searchParams.append('addressCode', addressCode)
  }

  if (begin !== null && begin !== undefined) {
    searchParams.append('begin', begin)
  }

  if (end !== null && end !== undefined) {
    searchParams.append('end', end)
  }

  if (workOrderType !== null && workOrderType !== undefined && workOrderType !== '') {
    searchParams.append('workOrderType', workOrderType)
  }

  if (inventoryNumber !== null && inventoryNumber !== undefined && inventoryNumber !== '') {
    searchParams.append('inventoryNumber', inventoryNumber)
  }

  if (excel !== null && excel !== undefined) {
    searchParams.append('excel', excel)
  }

  url.search = searchParams.toString()

  const newUrl = url.toString()

  if (excel) {
    return downloadRequest(newUrl)
  }

  return getRequest(newUrl)
}

export async function sendEmail() {
  return getRequestReportViewer(`report/workordersendtomail?id=${getWorkorderID()}&token=${token}`)
}

export async function windowOpen(url, dontAddToken) {
  try {
    if (dontAddToken === undefined) {
      const win = window.open(`${url}&token=${token}`, '_blank')
      win.focus()
    } else {
      const win = window.open(`${url}`, '_blank')
      win.focus()
    }
  } catch (e) {
    notification.error({
      message: 'Window Fehler',
      description: e.message,
    })
  }
}
