const actions = {
  REQUESTING: 'ProtocolAssignmentList/REQUESTING',
  RESPONSE_ERROR: 'ProtocolAssignmentList/RESPONSE_ERROR',

  FETCH_PROTOCOLASSIGNMENT_LIST: 'ProtocolAssignmentList/FETCH',
  LIST_PROTOCOLASSIGNMENT: 'ProtocolAssignment/LIST',

  FETCH_PROTOCOLASSIGNMENT: 'ProtocolAssignment/FETCH',
  NEW_PROTOCOLASSIGNMENT: 'ProtocolAssignment/NEW',
  EDIT_PROTOCOLASSIGNMENT: 'ProtocolAssignment/EDIT',
  RESET_PROTOCOLASSIGNMENT: 'ProtocolAssignment/RESET',

  RE_FETCH_PROTOCOLASSIGNMENT_LIST: 'ProtocolAssignmentList/REFRESH',
  SAVE_PROTOCOLASSIGNMENT: 'ProtocolAssignment/SAVE',
  SAVE_PROTOCOLASSIGNMENT_RECIEVED: 'ProtocolAssignment/SAVE_PROTOCOLASSIGNMENT_RECIEVED',

  DELETE_PROTOCOLASSIGNMENT: 'ProtocolAssignment/DELETE',
  DELETE_PROTOCOLASSIGNMENT_RECIEVED: 'ProtocolAssignment/DELETE_RECIEVED',
}

export default actions
