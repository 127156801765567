const actions = {
  REQUESTING: 'Invoice2s/REQUESTING',
  RESPONSE_ERROR: 'Invoice2s/RESPONSE_ERROR',

  FETCH_INVOICE2S_SILENT: 'FETCH_INVOICE2S_SILENT',
  FETCH_INVOICE2S_SILENT_RECIEVED: 'FETCH_INVOICE2S_SILENT',

  FETCH_INVOICE2S: 'Invoice2s/FETCH',
  LIST_INVOICE2: 'Invoice2/LIST',

  FETCH_INVOICE2: 'Invoice2/FETCH',
  NEW_INVOICE2: 'Invoice2/NEW',
  EDIT_INVOICE2: 'Invoice2/EDIT',

  RESET_INVOICE2: 'Invoice2/RESET',
  RESET_INVOICE2_rec: 'Invoice2/RESET_INVOICE2_rec',

  RE_FETCH_INVOICE2S: 'Invoice2s/REFRESH',
  SAVE_INVOICE2: 'Invoice2/SAVE',
  SAVE_INVOICE2_RECIEVED: 'Invoice2/SAVE_INVOICE2_RECIEVED',

  DELETE_INVOICE2: 'Invoice2/DELETE',
  DELETE_INVOICE2_RECIEVED: 'Invoice2/DELETE_RECIEVED',

  EDIT_EOK: 'EDIT_EOK',
  EDIT_EOK_RECIEVED: 'EDIT_EOK_RECIEVED',

  CREATE_INVOICE2: 'INVOICE2/CREATE_INVOICE2',
  CREATE_INVOICE2_RECIEVED: 'INVOICE2/CREATE_INVOICE2/RECIEVED',

  CREATE_INVOICE2_RESET: 'INVOICE2/CREATE_INVOICE2_RESET',
  CREATE_INVOICE2_RESET_RECIEVED: 'INVOICE2/CREATE_INVOICE2_RESET_RECIEVED',

  CREATE_INVOICE2_BYWORKORDERID: 'INVOICE2/CREATE_INVOICE2_BYWORKORDERID',
  CREATE_INVOICE2_BYWORKORDERID_RECIEVED: 'INVOICE2/CREATE_INVOICE2_BYWORKORDERID_RECIEVED',
}

export default actions
