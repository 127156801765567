/*eslint-disable*/

import React from 'react'
import Loadable from 'react-loadable'
import Loader from 'components/layout/Loader'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/logout',
    Component: loadable(() => import('pages/system/logout')),
    exact: true,
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
  {
    path: '/system/403',
    Component: loadable(() => import('pages/system/403')),
    exact: true,
  },
  {
    path: '/dashboard/analytics',
    Component: loadable(() => import('pages/dashboard/analytics')),
    exact: true,
  },
  {
    path: '/contracts',
    Component: loadable(() => import('pages/contracts')),
    exact: true,
  },

  {
    path: '/workorders',
    Component: loadable(() => import('pages/workorders/index2')),
    exact: true,
  },
  {
    path: '/workorders/t2/:id',
    Component: loadable(() => import('pages/workorders/edit-wo-general')),
    exact: true,
  },
  {
    path: '/costTypeDefinitions',
    Component: loadable(() => import('pages/costTypeDefinitions')),
    exact: true,
  },
  {
    path: '/costTypes',
    Component: loadable(() => import('pages/costTypes')),
    exact: true,
  },
  {
    path: '/intervals',
    Component: loadable(() => import('pages/intervals')),
    exact: true,
  },
  {
    path: '/contractyears',
    Component: loadable(() => import('pages/contractYears')),
    exact: true,
  },
  {
    path: '/suppliers',
    Component: loadable(() => import('pages/suppliers')),
    exact: true,
  },
  {
    path: '/countries',
    Component: loadable(() => import('pages/countrys')),
    exact: true,
  },
  {
    path: '/postalcodes',
    Component: loadable(() => import('pages/postalCode2s')),
    exact: true,
  },
  {
    path: '/users',
    Component: loadable(() => import('pages/userss/index2')),
    exact: true,
  },
  {
    path: '/technicians',
    Component: loadable(() => import('pages/technicians')),
    exact: true,
  },
  {
    path: '/workOrderTypes',
    Component: loadable(() => import('pages/workOrderTypes')),
    exact: true,
  },
  {
    path: '/inventoryclasses',
    Component: loadable(() => import('pages/inventoryClasss')),
    exact: true,
  },
  {
    path: '/maininventories',
    Component: loadable(() => import('pages/mainInventory')),
    exact: true,
  },

  {
    path: '/inventoryClassCostTypes',
    Component: loadable(() => import('pages/inventoryClassCostTypes/index2')),
    exact: true,
  },
  {
    path: '/locations',
    Component: loadable(() => import('pages/locations')),
    exact: true,
  },
  {
    path: '/areas',
    Component: loadable(() => import('pages/areas')),
    exact: true,
  },
  {
    path: '/addresses',
    Component: loadable(() => import('pages/addresss')),
    exact: true,
  },
  {
    path: '/inventories',
    Component: loadable(() => import('pages/inventorys/index2')),
    exact: true,
  },
  {
    path: '/inventoryServices',
    Component: loadable(() => import('pages/inventoryServices')),
    exact: true,
  },
  {
    path: '/reports/workorder',
    Component: loadable(() => import('pages/reports/workorder')),
    exact: true,
  },
  {
    path: '/reports/preventivemaintenance',
    Component: loadable(() => import('pages/reports/preventiveMaintenance')),
    exact: true,
  },
  {
    path: '/reports/billingtabulation',
    Component: loadable(() => import('pages/reports/billingTabulation')),
    exact: true,
  },
  {
    path: '/reports/inventory',
    Component: loadable(() => import('pages/reports/inventory')),
    exact: true,
  },
  {
    path: '/reports/invoice',
    Component: loadable(() => import('pages/reports/invoice')),
    exact: true,
  },
  {
    path: '/reports/part',
    Component: loadable(() => import('pages/reports/part')),
    exact: true,
  },
  {
    path: '/parts',
    Component: loadable(() => import('pages/parts/index2')),
    exact: true,
  },
  {
    path: '/inventoryLogs',
    Component: loadable(() => import('pages/inventoryLogs')),
    exact: true,
  },
  {
    path: '/inventories/history/:id',
    Component: loadable(() => import('pages/inventoryLogs/history')),
    exact: true,
  },
  {
    path: '/inventoryProductInformations',
    Component: loadable(() => import('pages/inventoryProductInformations/index2')),
    exact: true,
  },
  {
    path: '/reports/technician',
    Component: loadable(() => import('pages/reports/technician')),
    exact: true,
  },
  {
    path: '/repairLimits',
    Component: loadable(() => import('pages/repairLimits/index2')),
    exact: true,
  },
  {
    path: '/inventoryServiceLabors',
    Component: loadable(() => import('pages/inventoryServiceLabors/index2')),
    exact: true,
  },
  {
    path: '/reports/status-report',
    Component: loadable(() => import('pages/reports/status')),
    exact: true,
  },
  {
    path: '/serviceRequests',
    Component: loadable(() => import('pages/serviceRequests')),
    exact: true,
  },
  {
    path: '/completed-workorders',
    Component: loadable(() => import('pages/reports/complete-wo')),
    exact: true,
  },
  {
    path: '/technicianParts',
    Component: loadable(() => import('pages/technicianParts/index2')),
    exact: true,
  },
  {
    path: '/technicianDailyRecords/:id',
    Component: loadable(() => import('pages/technicianDailyRecords')),
    exact: true,
  },
  {
    path: '/technicianDailyReports',
    Component: loadable(() => import('pages/technicianDailyReports/index2')),
    exact: true,
  },
  {
    path: '/partTransfers',
    Component: loadable(() => import('pages/partTransfers/index2')),
    exact: true,
  },
  {
    path: '/pushnotifications',
    Component: loadable(() => import('pages/pushnotification')),
    exact: true,
  },
  {
    path: '/nextcontrols',
    Component: loadable(() => import('pages/nextcontrols')),
    exact: true,
  },
  {
    path: '/workOrderCostTypeTotals',
    Component: loadable(() => import('pages/workOrderCostTypeTotals')),
    exact: true,
  },
  {
    path: '/completed-wo-general-contract',
    Component: loadable(() => import('pages/reports/completed-wo-general-contract')),
    exact: true,
  },
  {
    path: '/proposals',
    Component: loadable(() => import('pages/proposals/index2')),
    exact: true,
  },
  {
    path: '/invoices',
    Component: loadable(() => import('pages/invoices/index2')),
    exact: true,
  },
  {
    path: '/invoices/:id',
    Component: loadable(() => import('pages/invoices/edit')),
    exact: true,
  },
  {
    path: '/proposals/:id',
    Component: loadable(() => import('pages/proposals/edit-pro')),
    exact: true,
  },
  {
    path: '/clinPaymentTypes',
    Component: loadable(() => import('pages/clinPaymentTypes')),
    exact: true,
  },
  {
    path: '/materialPriceDefinitions',
    Component: loadable(() => import('pages/materialPriceDefinitions')),
    exact: true,
  },
  {
    path: '/contactPersons',
    Component: loadable(() => import('pages/contactPersons')),
    exact: true,
  },
  {
    path: '/clinBudgets',
    Component: loadable(() => import('pages/clinBudgets')),
    exact: true,
  },
  {
    path: '/invoice2s',
    Component: loadable(() => import('pages/invoice2s')),
    exact: true,
  },
  {
    path: '/emailDeliverys',
    Component: loadable(() => import('pages/emailDeliverys')),
    exact: true,
  },
  {
    path: '/protocolassignment',
    Component: loadable(() => import('pages/protocolAssignment')),
    exact: true,
  },
  {
    path: '/companies',
    Component: loadable(() => import('pages/companys')),
    exact: true,
  },
  {
    path: '/workOrderStatusTables',
    Component: loadable(() => import('pages/workOrderStatusTables')),
    exact: true,
  },
]

export default routes
